<div class="containerAcesso">
  <!-- ENTRAR -->
  <form *ngIf="!salaTelemedicina"
        class="containerAcesso_formulario row no-gutters justify-content-center align-items-center"
        name="salaForm"
        [formGroup]="salaForm"
        (ngSubmit)="submit(salaForm)">
    <div class="col-md-4 col-sm-6">
      <div class="card m-3 px-3">
        <div class="card-body">
          <div class="form-row">
            <div class="col-12 d-flex justify-content-center mt-3">
              <img class="logo"
                   src="../../../../../assets/img/logos/siss-logo.svg" />
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <h1 class="containerAcesso_formulario_titulo">Participar de uma sessão de vídeo</h1>
            </div>
            <div></div>
            <div class="form-group col-12 mt-4">
              <label class="sr-only" for="nome">Nome do Participante</label>
              <input #loginInput
                     autofocus
                     class="form-control form-control-sm"
                     [class.is-invalid]="nome.invalid && nome.touched"
                     formControlName="nome"
                     id="nome"
                     name="nome"
                     placeholder="Nome do Participante"
                     type="text" />
              <div class="invalid-feedback feedback-icon">
                <i class="fa fa-times"></i>
              </div>
              <div *ngIf="nome.invalid && nome.touched">
                <span class="erro" *ngIf="nome.errors.required">Campo obrigatório</span>
              </div>
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="form-group col-12">
              <label class="sr-only" for="senha">Sala (Sessão)</label>
              <input class="form-control form-control-sm"
                     [class.is-invalid]="sessao.invalid && sessao.touched"
                     formControlName="sessao"
                     id="sessao"
                     name="sessao"
                     placeholder="Sala (Sessão)"
                     type="text" />
              <div class="invalid-feedback feedback-icon">
                <i class="fa fa-times"></i>
              </div>
              <div *ngIf="sessao.invalid && sessao.touched">
                <span class="erro" *ngIf="sessao.errors.required">Campo obrigatório</span>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="form-group col-12">
              <button class="btn btn-block btn-primary"
                      title="Entrar"
                      type="submit"
                      id="btnAcessar">
                Entrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-container *ngIf="salaTelemedicina">
  <form name="telemedicinaForm"
        [formGroup]="telemedicinaForm">
    <input id="userName" name="userName" type="hidden" />
  </form>
    <div class="containerSala">
      
  
  <header id="topnav">

    <div class="topbar-main">
      <div class="container-fluid">
        <!-- Logo container-->
        <!-- Image Logo -->
        <img class="logo-top" src="../../../../../assets/img/logos/siss-logo.svg" />
        <div class="float-right mt-2 p-0">
          <input class="btn btn-large btn-primary" type="button" id="buttonLeaveSession" onmouseup="_sairSession()" value="Sair da sessao">
          <input class="btn btn-large" type="button" id="buttonAbrirChat" onclick="openChat()" value="Abrir Chat">
        </div>
        <!-- End Logo container-->
        <!-- end menu-extras -->
      </div> <!-- end container -->
    </div>
    <!-- end topbar-main -->
  </header>
  <div id="session" class="row mt-4">
    <div class="col-12">
      <!--<div id="main-video" class="col-md-6 float-left">
    <p></p>
    <video autoplay playsinline="true" muted></video>
  </div>-->
      <div id="video-container" class="col-12"></div>
      <!--<div class="col-md-6 float-left">

  </div>-->
      <div class="chat-popup" id="divChat" style="display: none;">
        <div class="content">
          <div style="text-align: center; background: #eeeeee;" text-align>
            <h3>
              Chat SISS
              <button type="button" class="btn cancel float-right" onclick="fecharChat()">X</button>
            </h3>

          </div>
          <div class="messages" id="chat"></div>
          <form onsubmit="return false;">
            <input type="text" class="form-control" id="mensagem" placeholder="Voce esta escrevendo..." />
            <button type="button" class="btn btn-success" onclick="enviar_mensagem()">Enviar</button>
          </form>

        </div>
      </div>


    </div>
  </div>

  </div>

</ng-container>
<input id="nomeUsuario" name="nomeUsuario" type="hidden" />
<input id="conexaoId" name="conexaoId" type="hidden" />
<template data-template="message">
  <div class="message">
    <div class="message__name"></div>
    <div class="message__bubble"></div>
  </div>
</template>
