<ng-container>
    <div class="containerSala">
      
  
  <header id="topnav">

    <div class="topbar-main">
      <div class="container-fluid">
        <!-- Logo container-->
        <!-- Image Logo -->
        <img class="logo-top" src="../../../../../assets/img/logos/siss-logo.svg" />
        <div class="float-right mt-2 p-0">
          <input class="btn btn-large" type="button" id="buttonAbrirChat" onclick="openChat()" value="Abrir Chat">
        </div>
        <!-- End Logo container-->
        <!-- end menu-extras -->
      </div> <!-- end container -->
    </div>
    <!-- end topbar-main -->
  </header>
  <div id="session" class="row mt-4">
    <div class="col-12">
      <!--<div id="main-video" class="col-md-6 float-left">
        <p></p>
        <video autoplay playsinline="true" muted></video>
      </div>-->
      <div id="video-container" class="col-12"></div>
      <div class="chat-popup" id="divChat" style="display: none;">
        <div class="content">
          <div style="text-align: center; background: #eeeeee;" text-align>
            <h3>
              Chat SISS
              <button type="button" class="btn cancel float-right" onclick="fecharChat()">X</button>
            </h3>
           
          </div>
          <div class="messages" id="chat"></div>
          <form onsubmit="return false;">
            <input type="text" class="form-control" id="mensagem" placeholder="Voce esta escrevendo..." />
            <button type="button" class="btn btn-success" onclick="enviar_mensagem()">Enviar</button>
          </form>
          
        </div>
      </div>


    </div>
  </div>
  </div>

</ng-container>
<input id="nomeUsuario" name="nomeUsuario" type="hidden" />
<input id="conexaoId" name="conexaoId" type="hidden" />
<template data-template="message">
  <div class="message">
    <div class="message__name"></div>
    <div class="message__bubble"></div>
  </div>
</template>
