<div class="containerLogin">
  <!-- LOGIN -->
  <form
    *ngIf="!esqueciSenha && !selecionarEstrutura"
    class="containerLogin_formulario row no-gutters justify-content-center align-items-center"
    name="loginForm"
    [formGroup]="loginForm"
    (ngSubmit)="submit(loginForm)"
  >
    <div class="col-md-4 col-sm-6">
      <div class="card m-3 px-3">
        <div class="card-body">
          <div class="form-row">
            <div class="col-12 d-flex justify-content-center mt-3">
              <img
                class="logo"
                src="../../../../../assets/img/logos/siss-logo.svg"
              />
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <h1 class="containerLogin_formulario_titulo">Seja bem-vindo</h1>
            </div>
            <div></div>
            <div class="form-group col-12 mt-4">
              <label class="sr-only" for="login">Login</label>
              <input
                #loginInput
                autofocus
                class="form-control form-control-sm"
                [class.is-invalid]="login.invalid && login.touched"
                formControlName="login"
                id="login"
                name="login"
                placeholder="Usuário"
                type="text"
              />
              <div class="invalid-feedback feedback-icon">
                <i class="fa fa-times"></i>
              </div>
              <div *ngIf="login.invalid && login.touched">
                <span class="erro" *ngIf="login.errors.required"
                  >Campo obrigatório</span
                >
              </div>
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="form-group col-12">
              <label class="sr-only" for="senha">Senha</label>
              <input
                class="form-control form-control-sm"
                [class.is-invalid]="senha.invalid && senha.touched"
                formControlName="senha"
                id="senha"
                name="senha"
                placeholder="Senha"
                type="password"
              />
              <div class="invalid-feedback feedback-icon">
                <i class="fa fa-times"></i>
              </div>
              <div *ngIf="senha.invalid && senha.touched">
                <span class="erro" *ngIf="senha.errors.required"
                  >Campo obrigatório</span
                >
                <span class="erro" *ngIf="senha.errors.minlength"
                  >A senha deve conter ao menos 6 caracteres.</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-around mt-2">
            <u
              ><a
                class="containerLogin_formulario_link"
                (click)="esqueciSenha = true"
              >
                Esqueceu a senha?
              </a></u
            >
          </div>
          <div class="form-row mt-4">
            <div class="form-group col-12">
              <button
                class="btn btn-block btn-primary"
                title="Acessar"
                type="submit"
                id="btnAcessar"
              >
                Acessar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- ESQUECI MINHA SENHA -->
  <ng-container *ngIf="esqueciSenha">
    <form
      class="containerLogin_formulario row no-gutters justify-content-center align-items-center"
      name="esqueciSenhaForm"
      [formGroup]="esqueciSenhaForm"
      (ngSubmit)="recuperarSenha(esqueciSenhaForm)"
    >
      <div class="col-md-5 col-sm-6">
        <div class="card m-3 px-3">
          <div class="card-body">
            <div class="form-row">
              <div class="col-12 d-flex justify-content-center mt-3">
                <img
                  class="logo"
                  src="../../../../../assets/img/logos/logo_eicon_interna.svg"
                />
              </div>
              <div class="col-12 d-flex justify-content-center mt-4">
                <h1 class="containerLogin_formulario_titulo">
                  Recuperar senha
                </h1>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <label
                  class="forgot-feedback error mt-2"
                  *ngIf="esqueciSenhaMsg.type == 'error'"
                  >Usuário não encontrado!</label
                >
                <label
                  class="forgot-feedback success mt-2"
                  *ngIf="esqueciSenhaMsg.type == 'success'"
                  >Email enviado com sucesso!</label
                >
              </div>
              <div class="form-group col-12 mt-4">
                <label class="sr-only" for="email">Usuário</label>
                <input
                  autofocus
                  class="form-control form-control-sm"
                  [class.is-invalid]="
                    (email.invalid && email.touched) ||
                    esqueciSenhaMsg.type == 'error'
                  "
                  formControlName="email"
                  id="email"
                  name="email"
                  placeholder="usuario@eicon.com.br"
                  type="email"
                />
                <div class="invalid-feedback feedback-icon">
                  <i class="fa fa-times"></i>
                </div>
                <div *ngIf="email.invalid && email.touched">
                  <span class="erro" *ngIf="email.errors.required"
                    >Campo obrigatório</span
                  >
                  <span class="erro" *ngIf="email.errors.email"
                    >E-mail inválido</span
                  >
                </div>
                <div *ngIf="email.invalid == false || email.touched == false">
                  <span class="erro" *ngIf="esqueciSenhaMsg.type == 'error'"
                    >Usuário não encontrado</span
                  >
                </div>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <button
                class="btn btn-sm btn-secondary"
                (click)="esqueciSenha = false; limpaEsqueciSenha()"
                title="voltar"
              >
                Voltar
              </button>
              <button
                class="btn btn-sm btn-warning ml-2"
                (click)="recuperarSenha(esqueciSenhaForm)"
                title="recuperar"
              >
                Recuperar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>

  <!-- SELECIONAR ESTRUTURA -->
  <form
    class="containerLogin_formulario row no-gutters justify-content-center align-items-center"
    *ngIf="selecionarEstrutura"
  >
    <div class="col col-md-6 col-sm-6">
      <div class="card m-3 px-3">
        <div class="card-body">
          <div class="form-row">
            <div class="col-12 d-flex justify-content-center mt-3">
              <img
                class="logo"
                src="../../../../../assets/img/logos/logo_eicon_interna.svg"
              />
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <h1 class="containerLogin_formulario_titulo">
                Selecione uma organização
              </h1>
            </div>
            <table
              class="table table-bordered table-striped table-sm"
              datatable="ng"
            >
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th class="text-center">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let estrutura of estruturas">
                  <td>{{ estrutura.valor }}</td>
                  <td class="text-center">
                    <a
                      (click)="logarEstrutura(estrutura.chave, estrutura.id)"
                      class="containerLogin_formulario_link"
                      title="selecionar"
                    >
                      <img
                        alt="Avançar"
                        src="../../../../../assets/img/icons/seta-esquerda-proximo-prosseguir-2.svg"
                        width="17px"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="ml-auto pt-3">
              <button
                class="btn btn-sm btn-secondary float-right"
                (click)="selecionarEstrutura = false"
                title="voltar"
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div
    class="containerLogin_rodape d-flex justify-content-center align-items-center"
  >
    <span>&copy; 2020 | Desenvolvido por Eicon</span>
  </div>
</div>
