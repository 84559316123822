<div
  *ngIf="
    showUserDropdown ||
    showEixosDropdown ||
    showNotificationDropdown ||
    showCompaniesDropdown
  "
  (click)="
    showUserDropdown = false;
    showEixosDropdown = false;
    showNotificationDropdown = false;
    showCompaniesDropdown = false
  "
  class="dropdown-overlay"
></div>
<div class="header-container fixed-top">
  <header class="header navbar navbar-expand-sm">
    <ul class="navbar-item theme-brand flex-row text-center">
      <li class="nav-item theme-logo">
        <a href="index.html">
          <svg class="logo">
            <use
              class="logo_icon"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#logo_eicon_interna"
            ></use>
          </svg>
        </a>
      </li>
      <li class="nav-item theme-text">
        <div class="logoPref">
          <div>
            <img src="assets/img/placeholder/brasao-modelo.png" alt="logo" />
          </div>
          <div>
            <h4>{{ cidade }}</h4>
            <p>{{ organizacaoCliente }}</p>
          </div>
        </div>
      </li>
    </ul>

    <ul class="navbar-item flex-row ml-md-auto" style="height: 45px;">
      <app-pesquisa-menu></app-pesquisa-menu>

      <app-usuario
        [showUserDropdown]="showUserDropdown"
        [user]="true"
        [webUser]="false"
        (clickUserDropDown)="
          showUserDropdown = true;
          showEixosDropdown = false;
          showNotificationDropdown = false;
          showCompaniesDropdown = false
        "
      ></app-usuario>

      <li class="user-atach">
        <div class="nomeCargo">
          <p>{{ nomeCliente }}</p>
          <span>{{ perfilCliente }}</span>
        </div>
      </li>

      <app-eixos
        [showEixosDropdown]="showEixosDropdown"
        (clickEixosDropDown)="
          showUserDropdown = false;
          showEixosDropdown = true;
          showNotificationDropdown = false;
          showCompaniesDropdown = false
        "
      ></app-eixos>

      <app-notificacoes
        [showNotificationDropdown]="showNotificationDropdown"
        (clickNotificationDropDown)="
          showUserDropdown = false;
          showEixosDropdown = false;
          showNotificationDropdown = true;
          showCompaniesDropdown = false
        "
      ></app-notificacoes>

      <app-empresas
        [showCompaniesDropdown]="showCompaniesDropdown"
        [user]="false"
        (clickCompaniesDropDown)="
          showUserDropdown = false;
          showEixosDropdown = false;
          showNotificationDropdown = false;
          showCompaniesDropdown = true
        "
      ></app-empresas>

      <app-usuario
        class="user-mobile-responsive"
        [showUserDropdown]="showUserDropdown"
        [user]="false"
        [webUser]="true"
        (clickUserDropDown)="
          showUserDropdown = true;
          showEixosDropdown = false;
          showNotificationDropdown = false;
          showCompaniesDropdown = false
        "
      >
        <div class="nomeCargo">
          <p>{{ nomeCliente }}</p>
          <span>{{ organizacaoCliente }}</span>
        </div>
      </app-usuario>
    </ul>
  </header>
</div>
