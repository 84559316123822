
<li class="nav-item dropdown eicon-profile-dropdown"
    [class.show]="showCompaniesDropdown"
    [class.user-atach]="true"
    >
  <a href="javascript:void(0);"
     class="dropdown-toggle"
     id="eiconProfileDropdown"
     data-toggle="dropdown"
     aria-haspopup="true"
     aria-expanded="true"
     title="Soluções Eicon"
     >
     <svg class="icone-header">
      <use
        class=""
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xlink:href="#eicon-logo"
      ></use>
    </svg>
  </a>
  <div class="dropdown-menu position-absolute"
       [class.show]="showCompaniesDropdown"
       aria-labelledby="eiconProfileDropdown"
       style="margin-right: 10px;">
    <p>Soluções Eicon</p>
    <div class="logosEicon">
      <div>

        <a href="http://www.acto.net.br/" target="_blank" title="Acto">
          <svg class="icone-solucoes">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#_logo_Acto"
            ></use>
          </svg>
        </a>
      </div>
      <div>
        <a href="http://www.siss.com.br/" target="_blank" title="Siss">
          <svg class="icone-solucoes">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#_logo_Siss"
            ></use>
          </svg>
        </a>
      </div>
      <div>
        <a href="http://www.gdeonline.com.br/" target="_blank" title="Gde">
          <svg class="icone-solucoes">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#_logo_Gde"
            ></use>
          </svg>
        </a>
      </div>
      <div>
        <a href="http://www.gier.com.br/" target="_blank" title="Gier">
          <svg class="icone-solucoes">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#_logo_Gier"
            ></use>
          </svg>
        </a>

      </div>
    </div>
    <div class="logosEicon">
      <div>

        <a href="http://www.giexonline.com.br/" target="_blank" title="Giex">
          <svg class="icone-solucoes">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#_logo_Giex"
            ></use>
          </svg>
        </a>
      </div>
      <div>
        <a href="http://www.giss.com.br/" target="_blank" title="GissOnLine">
          <svg class="icone-solucoes">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#_logo_GissOnline"
            ></use>
          </svg>
        </a>
      </div>
      <div>
        <a href="http://www.icadonline.com.br/" target="_blank" title="iCad">
          <svg class="icone-solucoes">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#_logo_iCad"
            ></use>
          </svg>
        </a>
      </div>
    </div>
  </div>
</li>

