import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, Injector } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';


import { createCustomElement } from '@angular/elements';
import { ElementZoneStrategyFactory } from 'elements-zone-strategy';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { FlexLayoutModule } from '@angular/flex-layout';


// Pipes
import { LinkifyPipe } from './components/openvidu/shared/pipes/linkfy';
import {
  HasChatPipe,
  HasAudioPipe,
  HasVideoPipe,
  IsAutoPublishPipe,
  HasScreenSharingPipe,
  HasFullscreenPipe,
  HasLayoutSpeakingPipe,
  HasExitPipe
} from './components/openvidu/shared/pipes/ovSettings.pipe';
import { TooltipListPipe } from './components/openvidu/shared/pipes/tooltipList.pipe';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NaoEncontradoComponent } from "./pages/outros/erros/nao-encontrado/nao-encontrado.component";
import {
  NgbButtonsModule,
  NgbDropdownModule,
  NgbTabsetModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { InlineSVGModule } from "ng-inline-svg";
import { HomeComponent } from "./pages/home/home.component";
import { CoreComponent } from "./components/core/core.component";
import { ComponentsModule } from "./components/components.module";
import { LoginComponent } from "./pages/outros/autenticacao/login/login.component";
import { SalaComponent } from "./pages/outros/siat-js/sala/sala.component";
import { SalaVideoComponent } from "./pages/outros/siat-js/sala-video/sala-video.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Components OpenVidu
import { StreamComponent } from './components/openvidu/shared/components/stream/stream.component';
import { ChatComponent } from './components/openvidu/shared/components/chat/chat.component';
import { DialogExtensionComponent } from './components/openvidu/shared/components/dialog-extension/dialog-extension.component';
import { OpenViduVideoComponent } from './components/openvidu/shared/components/stream/ov-video.component';
import { DialogErrorComponent } from './components/openvidu/shared/components/dialog-error/dialog-error.component';
import { ToolbarComponent } from './components/openvidu/shared/components/toolbar/toolbar.component';
import { ToolbarLogoComponent } from './components/openvidu/shared/components/toolbar/logo.component';
import { RoomConfigComponent } from './components/openvidu/shared/components/room-config/room-config.component';
import { WebComponentComponent } from './pages/outros/siat/web-component/web-component.component';
import { VideoRoomComponent } from './pages/outros/siat/video-room/video-room.component';


import { LoaderService } from "./services/loader/loader.service";
import { LoaderInterceptor } from "./services/loader/loader.interceptor";
import { LoaderComponent } from "./components/loader/loader.component";
import { CryptoService } from './services/security/crypto.service';

// Services Openvidu
import { NetworkService } from './components/openvidu/shared/services/network/network.service';
import { OpenViduSessionService } from './components/openvidu/shared/services/openvidu-session/openvidu-session.service';
import { UtilsService } from './components/openvidu/shared/services/utils/utils.service';
import { DevicesService } from './components/openvidu/shared/services/devices/devices.service';
import { RemoteUsersService } from './components/openvidu/shared/services/remote-users/remote-users.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    SalaComponent,
    SalaVideoComponent,
    VideoRoomComponent,
    CoreComponent,
    NaoEncontradoComponent,
    VideoRoomComponent,
    StreamComponent,
    ChatComponent,
    DialogExtensionComponent,
    OpenViduVideoComponent,
    DialogErrorComponent,
    RoomConfigComponent,
    WebComponentComponent,
    ToolbarComponent,
    ToolbarLogoComponent,
    LinkifyPipe,
    HasChatPipe,
    HasAudioPipe,
    HasVideoPipe,
    IsAutoPublishPipe,
    HasScreenSharingPipe,
    HasFullscreenPipe,
    HasLayoutSpeakingPipe,
    HasExitPipe,
    TooltipListPipe
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    ComponentsModule,

    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatTooltipModule,
    MatBadgeModule,
    MatGridListModule,
    MatSelectModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatChipsModule,
    NgxLinkifyjsModule.forRoot()

  ],
  entryComponents: [DialogErrorComponent, WebComponentComponent],
  providers: [NetworkService, OpenViduSessionService, UtilsService, RemoteUsersService, DevicesService, CryptoService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    const strategyFactory = new ElementZoneStrategyFactory(WebComponentComponent, this.injector);
    const element = createCustomElement(WebComponentComponent, { injector: this.injector, strategyFactory });
    customElements.define('openvidu-webcomponent', element);
  }

  ngDoBootstrap() { }
}
