<div class="overlay content" *ngIf="isLoading">
  <div class="loader">
    <svg id="Camada_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">



      <style type="text/css">

        #path01 {
          fill: #69BFAB;
        }

        #path02 {
          fill: #12997E;
        }

        #path03 {
          fill: #69BFAB;
        }

        @keyframes rotateIcon {
          0% {
            transform: rotate(0deg);
          }

          50% {
            transform: rotate(360deg);
          }

          100% {
            transform: rotate(720deg);
          }
        }

        #animate01 {
          transform-origin: 50% 50% !important;
          animation: rotateIcon 3s infinite;
        }
      </style>

      <g id="animate01">
        <path id="path01" class="st0" d="M36.3,88.6c0,21.6,17.6,39.2,39.2,39.2s39.2-17.6,39.2-39.2c0-13.6-7.2-26.2-18.6-33.3
		c4,4.8,6.2,10.8,6.2,17.1c0,14.7-12,26.7-26.7,26.7s-26.7-12-26.7-26.7c0-6.3,2.2-12.3,6.2-17.1C43.4,62.4,36.3,75,36.3,88.6z" />

        <g id="XMLID_206_">
          <g id="XMLID_209_">
            <path id="path02" class="st1" d="M74.9,13.7c-34,0-61.6,27.6-61.6,61.6s27.6,61.6,61.6,61.6s61.6-27.6,61.6-61.6
				S109,13.7,74.9,13.7z M75.5,132.5c-24.2,0-43.9-19.7-43.9-43.9c0-6.1,1.2-11.9,3.5-17.1c-1.1-3.7-1.6-7.6-1.6-11.5
				c0-23.2,18.8-42,42-42s42,18.8,42,42c0,3.9-0.6,7.8-1.6,11.6c2.2,5.2,3.4,11,3.4,17C119.3,112.8,99.6,132.5,75.5,132.5z" />
          </g>
          <path id="path03" class="st0" d="M75.5,43.4c14.5,0,28.2,7.1,36.6,18.7c0-0.7,0.1-1.4,0.1-2.1c0-20.2-16.4-36.6-36.6-36.6
			c-20.2,0-36.6,16.4-36.6,36.6c0,0.6,0,1.3,0.1,1.9C47.4,50.2,60.8,43.4,75.5,43.4z" />
        </g>
      </g>
    </svg>

  </div>
</div>
