import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknEstrutura'),
    });
  }

  obterMenuPorTexto(descricao: string) {
    return this.http.get<any>(
      `${environment.ciphersUrl}/funcao/obter-menus-usuario`,
      {
        headers: this.httpHeaders,
        params: { descricao },
      }
    );
  }
}
