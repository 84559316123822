<li
  class="nav-item dropdown user-profile-dropdown"
  [class.show]="showUserDropdown"
  [class.user-atach]="user"
  [class.web-user]="webUser"
>
  <a
    href="javascript:void(0);"
    class="nav-link dropdown-toggle user"
    id="userProfileDropdown"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="true"
  >
    <img
      *ngIf="fotoCliente === 'null' || fotoCliente === ''"
      src="assets/img/placeholder/sem-foto.jpg"
      alt="avatar"
      style="border-radius: 50%;"
    />
    <img
      *ngIf="fotoCliente !== 'null' && fotoCliente !== ''"
      [src]="fotoCliente"
      alt="avatar"
      style="border-radius: 50%;"
    />
  </a>
  <div
    class="dropdown-menu position-absolute"
    [class.show]="showUserDropdown"
    aria-labelledby="userProfileDropdown"
  >
    <div class="">
      <p class="ml-2 mt-2">
        <ng-content></ng-content>
        <br />
        <small>
          <span class="font-weight-bold">
            ÚLTIMO ACESSO:
          </span>
          {{ usuarioInfos.ultimoAcesso | date: 'dd/MM/yyyy':'pt' }} às
          {{ usuarioInfos.ultimoAcesso | date: 'h:mm:ss':'pt' }}
          <br />
          <span class="font-weight-bold">
            IP:
          </span>
          {{ usuarioInfos.ipAddress }}
        </small>
      </p>
      <div class="dropdown-item">
        <a class="" href="#">
          <svg class="icone-header">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#meu-perfil"
            ></use>
          </svg>
          Meu Perfil</a
        >
      </div>
      <div class="dropdown-item">
        <a
          (click)="redirecionaSelecaoPerfil()"
          class="cursor-pointer"
          title="Trocar Perfil"
        >
          <svg class="icone-header">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#troca-perfil"
            ></use>
          </svg>
          Troca de Perfil</a
        >
      </div>
      <div class="dropdown-item">
        <a class="" href="#">
          <svg class="icone-header">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#troca-senha"
            ></use>
          </svg>
          Alterar Senha</a
        >
      </div>
      <div class="dropdown-item">
        <a (click)="logout()" class="cursor-pointer">
          <svg class="icone-header">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#sair"
            ></use>
          </svg>
          Sair</a
        >
      </div>
    </div>
  </div>
</li>
