<div id="dialogExtension">
	<mat-card>
		<mat-card-content>
			<h1 mat-dialog-title>Hello {{ nickname }}</h1>
			<div mat-dialog-content>
				<p>You need install this chrome extension and refresh the browser for can share your screen.</p>
			</div>
		</mat-card-content>
		<mat-card-actions align="center">
			<button mat-button (click)="onNoClick()" id="cancelButton">Cancel</button>
			<button mat-button (click)="goToChromePage()" cdkFocusInitial id="installButton">Install</button>
			<button mat-button *ngIf="this.isInstalled" (click)="refreshBrowser()">Refresh</button>
		</mat-card-actions>
	</mat-card>
</div>
