import { Component, OnInit, Input } from '@angular/core';

import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() navCollapsed;
  public menuItems: any;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.authenticationService.currentAxis.subscribe((axis) => {
      this.menuItems = axis.item;

      this.menuItems.map((item) => {
        item.item.map((funcionalidade) => {
          const pathArr = funcionalidade.rota.split('.');
          pathArr.splice(0, 1);
          const path = pathArr.join('/');
          funcionalidade.rotaSistema = '/' + path;
        });
      });
    });
  }
}
