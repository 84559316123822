import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

declare function _entrarSession(sessionId: string, nome: string): any;

@Component({
  templateUrl: './sala.component.html',
  styleUrls: ['./sala.component.scss'],
})
export class SalaComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject<void>();
  salaForm: FormGroup;
  telemedicinaForm: FormGroup;
  salaTelemedicina: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.salaForm = this.formBuilder.group({
      nome: ['', [Validators.required]],
      sessao: ['', [Validators.required, Validators.minLength(6)]],
    });
    this.telemedicinaForm = this.formBuilder.group({
      userName: ['', [Validators.required]]
    });
  }
  // Reconhece os campos
  get nome() {
    return this.salaForm.get('nome');
  }
  get sessao() {
    return this.salaForm.get('sessao');
  }

  get userName() {
    return this.telemedicinaForm.get('userName');
  }
    ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  submit(form) {
    if (this.salaForm.valid) {
      _entrarSession(
        this.salaForm.value.sessao,
        this.salaForm.value.nome);
      this.salaTelemedicina = true;
      this.telemedicinaForm.value.userName = this.salaForm.value.nome;

    } else {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }
  
}
