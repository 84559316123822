<div class="rodape">
    <div class="row">
        <div class="col col-12">
            Copyright © {{dataAtual}} <span></span> <a target="_blank"
               href="http://eicon.com.br">Eicon</a>. Todos os direitos reservados.
        </div>

        <div class="logoMkt col">
            <div>
                Produzido por:
            </div>
            <div>
                <svg class="logo">
                    <use class=""
                         xmlns:xlink="http://www.w3.org/1999/xlink"
                         xlink:href="#eicon_mkt"></use>
                </svg>
            </div>
        </div>
    </div>
</div>