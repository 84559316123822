import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ChatMessage } from '../../types/chat-type';
import { OpenViduSessionService } from '../openvidu-session/openvidu-session.service';
import { MatSidenav } from '@angular/material/sidenav';
import { RemoteUsersService } from '../remote-users/remote-users.service';
import { LoggerService } from '../logger/logger.service';
import { UtilsService } from '../utils/utils.service';
import { ILogger } from '../../types/logger-type';

@Injectable({
	providedIn: 'root'
})
export class ChatService {
	messagesObs: Observable<ChatMessage[]>;
	messagesUnreadObs: Observable<number>;
	toggleChatObs: Observable<boolean>;

	private chatComponent: MatSidenav;

	private _messageList = <BehaviorSubject<ChatMessage[]>>new BehaviorSubject([]);
	private _toggleChat = <BehaviorSubject<boolean>>new BehaviorSubject(false);

	private messageList: ChatMessage[] = [];
	private chatOpened: boolean;
	private messagesUnread = 0;
	private log: ILogger;

	private _messagesUnread = <BehaviorSubject<number>>new BehaviorSubject(0);

	constructor(
		private loggerSrv: LoggerService,
		private oVSessionService: OpenViduSessionService,
		private remoteUsersService: RemoteUsersService,
    private utilService: UtilsService
	) {
		this.log = this.loggerSrv.get('ChatService');
		this.messagesObs = this._messageList.asObservable();
		this.toggleChatObs = this._toggleChat.asObservable();
		this.messagesUnreadObs = this._messagesUnread.asObservable();
	}

	setChatComponent(chatSidenav: MatSidenav) {
		this.chatComponent = chatSidenav;
	}

	subscribeToChat() {
    const session = this.oVSessionService.getWebcamSession();
		session.on('signal', (event: any) => {
      const connectionId = event.from.connectionId;
      const data = this.getChatJson(event.data);
      const perfil = this.oVSessionService.getWebcamPerfil();
      const isMyOwnConnection = this.oVSessionService.isMyOwnConnection(connectionId);

      let avatar = this.utilService.getOpenViduAvatarPaciente();

      if (isMyOwnConnection && perfil == 'Paciente') {
        avatar = this.utilService.getOpenViduAvatarPaciente();
      }
      if (isMyOwnConnection && perfil != 'Paciente') {
        avatar = this.utilService.getOpenViduAvatarMedico();
      }

      if (!isMyOwnConnection && perfil == 'Paciente') {
        avatar = this.utilService.getOpenViduAvatarMedico();
      }
      if (!isMyOwnConnection && perfil != 'Paciente') {
        avatar = this.utilService.getOpenViduAvatarPaciente();
      }

			this.messageList.push({
				isLocal: isMyOwnConnection,
				nickname: data.nickname,
				message: data.message,
        userAvatar: avatar
			});
			this._messageList.next(this.messageList);
			if (!this.isChatOpened()) {
				this.addMessageUnread();
			}
		});
	}

	sendMessage(message: string) {
		message = message.replace(/ +(?= )/g, '');
		if (message !== '' && message !== ' ') {
			const data = {
				message: message,
				nickname: this.oVSessionService.getWebcamUserName()
			};
			const sessionAvailable = this.oVSessionService.getConnectedUserSession();
			sessionAvailable.signal({
				data: JSON.stringify(data),
				type: 'chat'
			});
		}
	}

	toggleChat() {
		this.log.d('Toggling chat');
		this.chatComponent.toggle().then(() => {
			this.chatOpened = this.chatComponent.opened;
			this._toggleChat.next(this.chatOpened);
			if (this.chatOpened) {
				this.messagesUnread = 0;
				this._messagesUnread.next(this.messagesUnread);
			}
		});
	}

	private isChatOpened(): boolean {
		return this.chatOpened;
	}
  private getChatJson(message: any): any {
	  
    try {
      return JSON.parse(message);
    } catch (e) {
		console.log('chat app' + message);
      return {
        nickname: 'Paciente',
        message: message
     }
    }
  }

	private addMessageUnread() {
		this.messagesUnread++;
		this._messagesUnread.next(this.messagesUnread);
	}
}
