import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./core/footer/footer.component";
import { SidebarComponent } from "./core/sidebar/sidebar.component";
import { SubHeaderComponent } from "./core/sub-header/sub-header.component";
import { BreadcrumbComponent } from "./core/sub-header/breadcrumb/breadcrumb.component";
import { SidebarEixoComponent } from "./core/sub-header/sidebar-eixo/sidebar-eixo.component";
import { HeaderComponent } from "./core/header/header.component";
import { EixosComponent } from "./core/header/eixos/eixos.component";
import { AjudaComponent } from "./core/header/ajuda/ajuda.component";
import { EmpresasComponent } from "./core/header/empresas/empresas.component";
import { NotificacoesComponent } from "./core/header/notificacoes/notificacoes.component";
import { UsuarioComponent } from "./core/header/usuario/usuario.component";
import { PesquisaMenuComponent } from "./core/header/pesquisa-menu/pesquisa-menu.component";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { SpinnerComponent } from "./spinner/spinner.component";
import { AlertModule } from "./alert/alert.module";
import { CardModule } from "./card/card.module";
import { ModalModule } from "./modal/modal.module";
import { LoaderComponent } from "./loader/loader.component";
import { LoaderService } from "../services/loader/loader.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoaderInterceptor } from "../services/loader/loader.interceptor";
import { Ng2CompleterModule } from "ng2-completer";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const components = [
  FooterComponent,
  SidebarComponent,
  SubHeaderComponent,
  BreadcrumbComponent,
  SidebarEixoComponent,
  HeaderComponent,
  EixosComponent,
  AjudaComponent,
  EmpresasComponent,
  NotificacoesComponent,
  UsuarioComponent,
  BreadcrumbComponent,
  SpinnerComponent,
  LoaderComponent,
  PesquisaMenuComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    AlertModule,
    CardModule,
    ModalModule,
    Ng2CompleterModule,
  ],
  exports: components,
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
})
export class ComponentsModule {}
