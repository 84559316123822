import { Component, OnInit } from '@angular/core';
import { CompleterService, CompleterData } from 'ng2-completer';
import { MenuService } from '../../.././../services/menu/menu.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pesquisa-menu',
  templateUrl: './pesquisa-menu.component.html',
  styleUrls: ['./pesquisa-menu.component.scss'],
})
export class PesquisaMenuComponent implements OnInit {
  dataService: CompleterData;
  inputBusca: string;
  formBusca: FormGroup;
  pesquisaFocus = false;
  menus = [];

  constructor(
    private completerService: CompleterService,
    private menuService: MenuService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  get busca() {
    return this.formBusca.controls.inputBusca;
  }

  ngOnInit(): void {
    this.formBusca = this.formBuilder.group({
      inputBusca: null,
    });
  }

  obterMenusPorTexto() {
    if (this.busca.value.length) {
      this.menuService.obterMenuPorTexto(this.busca.value).subscribe((res) => {
        this.menus = res.resultado;
        this.dataService = this.completerService.local(
          this.menus,
          'descricao',
          'descricao'
        );
      });
    }
  }

  selecionaMenu() {
    this.menus.forEach((menu) => {
      const eixoMenu = menu.rota.split('.')[1];
      const eixos = JSON.parse(
        this.authenticationService.currentUserValue.eixos
      );
      eixos.forEach((eixo, index) => {
        if (eixo.rota.split('.')[1] === eixoMenu) {
          this.authenticationService.setCurrentAxis(index);
        }
      });
      if (menu.descricao === this.busca.value) {
        const pathArr = menu.rota.split('.');
        pathArr.splice(0, 1);
        const path = pathArr.join('/');
        this.router.navigate(['/' + path]);
      }
    });
  }
}
