// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  sissUrl: "",
  srvBaseUrl: "",
  ciphersUrl: "",
  appId: "C2C5E736-DB91-46FB-9539-138BD18A359D",
  openvidu_url: 'CDMgZCT+k070vTdhculgyt65paih5CSFO6nJH5uJNCYZ5QK/wneh+36f5M7wfRmC',
  openvidu_secret: 'LpxgoyuABhl/1qgqAJGLIHPcv8K+/PwhqaStOFEMOo/f7/nrwaODqJ0XypElw48c',
  openvidu_avatar_medico: '../../../../assets/img/icons/icon-doctor.png',
  openvidu_avatar_paciente: '../../../../assets/img/icons/icon-paciente.png',

  logs: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
