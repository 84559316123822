<div
  class="d-none"
  aria-label="sprite icons"
  [inlineSVG]="'/assets/sprite.svg'"
></div>
<app-header></app-header>
<app-sub-header
  (onNavCollapse)="this.navCollapsed = !this.navCollapsed"
></app-sub-header>
<div
  class="main-container"
  [class.sidebar-closed]="navCollapsed"
  id="container"
>
  <div
    class="overlay"
    [class.show]="!this.navCollapsed"
    (click)="this.navCollapsed = !this.navCollapsed"
  ></div>
  <div class="search-overlay"></div>
  <app-sidebar
    class="pcoded-navbar"
    [ngClass]="{
      'navbar-collapsed': navCollapsed,
      'theme-horizontal': this.dattaConfig['layout'] === 'horizontal',
      'mob-open': navCollapsedMob
    }"
    [navCollapsed]="this.navCollapsed"
  ></app-sidebar>
  <div id="content" class="main-content">
    <div class="pcoded-wrapper">
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper conteudo-container">
              <router-outlet></router-outlet>
              <app-footer></app-footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
