import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
@Component({
  selector: 'app-eixos',
  templateUrl: './eixos.component.html',
  styleUrls: ['./eixos.component.scss'],
})
export class EixosComponent {
  @Input() showEixosDropdown: boolean;
  @Output() clickEixosDropDown = new EventEmitter();
  eixos: any;

  constructor(private authenticationService: AuthenticationService) {
    this.eixos = JSON.parse(this.authenticationService.currentUserValue.eixos);
    this.eixos.map((eixo) => {
      eixo.name = eixo.rota.split('.')[1];
    });
  }

  setAxis(index: number) {
    this.authenticationService.setCurrentAxis(index);
  }
}
