import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CoreComponent } from './components/core/core.component';
import { LoginComponent } from './pages/outros/autenticacao/login/login.component';
import { SalaComponent } from './pages/outros/siat-js/sala/sala.component';
import { SalaVideoComponent } from './pages/outros/siat-js/sala-video/sala-video.component';
import { VideoRoomComponent } from './pages/outros/siat/video-room/video-room.component';
import { AuthGuard } from './services/authentication/auth.guard';
import { NaoEncontradoComponent } from './pages/outros/erros/nao-encontrado/nao-encontrado.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'selecionar-estrutura',
    component: LoginComponent,
  },
  /*{ path: 'telemedicina', component: SalaComponent },*/
  { path: 'sala-atendimento', component: SalaVideoComponent },
  { path: 'siat/:roomName', component: VideoRoomComponent },
  {
    path: 'selecionar-estrutura',
    component: SalaComponent,
  },
  { path: '', redirectTo: 'gestao', pathMatch: 'full' },
  {
    path: '',
    component: CoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'configuracao',
        data: {
          breadcrumb: 'Início',
        },
        loadChildren: () =>
          import('./pages/configuracao/configuracao.module').then(
            (m) => m.ConfiguracaoModule
          ),
      },
      {
        path: 'gestao',
        data: {
          breadcrumb: 'Início',
        },
        loadChildren: () =>
          import('./pages/gestao/gestao.module').then((m) => m.GestaoModule),
      },
    ],
  },
  {
    path: '**',
    component: NaoEncontradoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
