import { Component, OnInit, Input, Output, HostListener, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

import { AuthenticationService } from "../../../../services/authentication/authentication.service";

@Component({
  selector: "app-usuario",
  templateUrl: "./usuario.component.html",
  styleUrls: ["./usuario.component.scss"],
})
export class UsuarioComponent implements OnInit {
  @Input() showUserDropdown: boolean;
  @Output() clickUserDropDown = new EventEmitter();
  @Input() user: boolean;
  @Input() webUser: boolean;

  fotoCliente: string;
  usuarioInfos: any;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fotoCliente = this.authenticationService.currentUserValue.logo;
    this.usuarioInfos = this.authenticationService.currentUserValue.infos;
  }

  redirecionaSelecaoPerfil() {
    this.router.navigate(["/selecionar-estrutura"]);
  }

  logout() {
    this.authenticationService.logout();
  }
}
