import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

declare function _entrarSessionUrl(): any;

@Component({
  templateUrl: './sala-video.component.html',
  styleUrls: ['./sala-video.component.scss'],
})
export class SalaVideoComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    _entrarSessionUrl();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  
}
