<ul class="navbar-nav flex-row leftSpacing breadcrumb-nav">
  <li>
    <div class="page-header">
      <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li
            *ngFor="let breadcrumb of breadcrumbItems"
            class="breadcrumb-item"
          >
            <a
              [routerLink]="breadcrumb.url"
              [class.router-link-active]="breadcrumb.url === currentUrl || breadcrumb.isAxis"
              >{{ breadcrumb.label }}</a
            >
          </li>
        </ol>
      </nav>
    </div>
  </li>
</ul>
