<div id="roomConfig" [ngStyle]="{ display: (ovSettings | isAutoPublish) ? 'none' : 'block' }">
	<app-dialog-extension
		*ngIf="showDialogExtension"
		[nickname]="localUsers[0].nickname"
		(cancel)="toggleDialogExtension()"
	></app-dialog-extension>

<!-- Modal -->
<!--<div class="modal" *ngIf="showConfigCard">
    <div class="modal-dialog modal-xl">
      <div class="modal-content cardContainer">

        <div class="modal-header cardHeader">
			<div class="headerLogo">
				<img
					id="header_img"
					alt="OpenVidu Logo"
					src="https://raw.githubusercontent.com/OpenVidu/openvidu-call/master/openvidu-call-front/src/assets/images/openvidu_logo_grey.png"
				/>
			</div>
			<h3 class="headerTitle">{{ mySessionId }}</h3>

			<button mat-mini-fab (click)="close()" class="closeButton">
				<mat-icon color="warn" matTooltip="Cancel">close</mat-icon>
			</button>
        </div>


        <div class="modal-body">

		  <div class="row align-items-center">
			<div class="col-sm-6 col-md-6 col-lg-6 leftSection">
				<div class="videoContainer">


					<div *ngFor="let localUser of localUsers; let i = index">

						<ov-video
							*ngIf="(localUser.isCamera() && hasVideoDevices) || localUser.isScreen()"
							[streamManager]="localUser.streamManager"

							[className]="localUsers.length > 1 && i === 0 ? 'ovVideoSmall' : ''"
						></ov-video>
						<div class="cameraMessageContainer" *ngIf="localUser.isCamera() && !hasVideoDevices">
							<span>Oops! Camera is not available</span>
						</div>
					</div>
				</div>

				<div class="avatarButton">
					<button mat-stroked-button (click)="takePhoto()">
						<mat-icon matTooltip="Take Photo">photo_camera</mat-icon>
						<span style="margin-left: 5px;">Capture Avatar</span>
					</button>
				</div>
			</div>

			<div class="col-sm-6 col-md-6 col-lg-6 rightSection">

				<div fxLayout="row" fxFill id="avatarSection" *ngIf="hasVideoDevices">
					<div fxLayout fxFlex>
						<div class="" fxFlex="30" fxLayoutAlign="center center">
							<h3 style="margin: auto;">Avatar</h3>
						</div>
						<div class="" fxFlex="70" fxLayoutAlign="center center">
							<div
								id="avatarContainer"
								(click)="setAvatar('video')"
								[style.background]="avatarSelected === 'video' ? 'lightgreen' : 'transparent'"
							>
								<div id="imgText" *ngIf="!videoAvatar">
									<span>Press Avatar Button</span>
								</div>
								<img id="avatarImg" *ngIf="videoAvatar" [src]="videoAvatar" />
							</div>
							<div
								id="avatarContainer"
								(click)="setAvatar('random')"
								[style.background]="avatarSelected === 'random' ? 'lightgreen' : 'transparent'"
							>
								<mat-spinner id="imgText" [diameter]="70" *ngIf="!randomAvatar" color="accent"></mat-spinner>
								<img id="avatarImg" *ngIf="randomAvatar" [src]="randomAvatar" />
							</div>
						</div>
					</div>
				</div>

				<div fxLayout="row" fxFill id="devicesSection" *ngIf="hasAudioDevices && (ovSettings | hasAudio)">
					<div fxLayout fxFlex>
						<div class="one" fxFlex="20" fxLayoutAlign="center center">
							<button mat-mini-fab (click)="toggleMic()" class="deviceButton">
								<mat-icon *ngIf="isAudioActive" matTooltip="Microphone Enabled">mic</mat-icon>
								<mat-icon *ngIf="!isAudioActive" color="warn" matTooltip="Microphone Disabled">mic_off</mat-icon>
							</button>
						</div>
						<div class="two" fxFlex="80" fxLayoutAlign="center center">
							<mat-form-field class="alternate-theme">
								<mat-select
									placeholder="Microphone Options"
									[ngModel]="isAudioActive && micSelected ? micSelected.device : 'None'"
									(selectionChange)="onMicrophoneSelected($event)"
								>
									<mat-option *ngFor="let microphone of microphones" [value]="microphone.device">
										{{ microphone.label }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
				</div>

				<div fxLayout="row" fxFill id="devicesSection" *ngIf="hasVideoDevices && (ovSettings | hasVideo)">
					<div fxLayout fxFlex>
						<div class="one" fxFlex="20" fxLayoutAlign="center center">
							<button mat-mini-fab (click)="toggleCam()" class="deviceButton">
								<mat-icon *ngIf="isVideoActive" matTooltip="Camera Enabled">videocam</mat-icon>
								<mat-icon *ngIf="!isVideoActive" color="warn" matTooltip="Camera Disabled">videocam_off</mat-icon>
							</button>
						</div>
						<div class="two" fxFlex="80" fxLayoutAlign="center center">
							<mat-form-field class="alternate-theme">
								<mat-select
									placeholder="Camera Options"
									[ngModel]="isVideoActive && camSelected ? camSelected.device : 'None'"
									(selectionChange)="onCameraSelected($event)"
								>
									<mat-option *ngFor="let camera of cameras" [value]="camera.device">
										{{ camera.label }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div fxLayout="row" fxFill id="devicesSection">
					<div fxLayout fxFlex>
						<div class="one" fxFlex="20" fxLayoutAlign="center center">
							<button mat-mini-fab (click)="setNicknameForm()" class="deviceButton">
								<mat-icon matTooltip="Nickname">person</mat-icon>
							</button>
						</div>
						<div class="two" fxFlex="80" fxLayoutAlign="center center">
							<form id="nicknameDialog" class="alternate-theme">
								<mat-form-field>
									<input
										matInput
										placeholder="Nickname"
										[formControl]="nicknameFormControl"
										[errorStateMatcher]="matcher"
										(keypress)="eventKeyPress($event)"
										autocomplete="off"
									/>
									<mat-error *ngIf="nicknameFormControl.hasError('required')">
										Nickname is <strong>required</strong>
									</mat-error>
									<mat-error *ngIf="nicknameFormControl.hasError('maxlength')">
										Nickname is <strong>too long!</strong>
									</mat-error>
								</mat-form-field>
							</form>
						</div>
					</div>
				</div>


			</div>
		  </div>

        </div>

        <div class="modal-footer" style="justify-content: center">
			<button mat-flat-button color="accent" (click)="joinSession()" id="joinButton">JOIN</button>

        </div>

      </div>
    </div>
  </div>-->
</div>

