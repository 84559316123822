<li class="search-component"
    style="width: 250px;"
    [class.focused]="pesquisaFocus">
  <form [formGroup]="formBusca">
    <ng2-completer textNoResults="Nenhum registro encontrado"
                   textSearching="Buscando menus..."
                   placeholder="{{ pesquisaFocus ? 'Pesquisar Funcionalidade' : '' }}"
                   inputClass="form-control form-control-sm"
                   inputId="menuBusca"
                   formControlName="inputBusca"
                   inputName="menu"
                   maxChars="100"
                   [datasource]="dataService"
                   [pause]="1000"
                   [minSearchLength]="1"
                   [selectOnClick]="true"
                   (keyup)="obterMenusPorTexto()"
                   (blur)="selecionaMenu(); pesquisaFocus = false"
                   (focus)="pesquisaFocus = true"></ng2-completer>
  </form>

  <div class="search-icon"
       [class.focused]="pesquisaFocus">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         stroke="currentColor"
         stroke-width="2"
         stroke-linecap="round"
         stroke-linejoin="round"
         class="feather feather-search">
      <circle cx="11"
              cy="11"
              r="8"></circle>
      <line x1="21"
            y1="21"
            x2="16.65"
            y2="16.65"></line>
    </svg>
  </div>
</li>